import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import React from 'react';
export default function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <button
          className="fixed bottom-5 right-5 bg-[#083bef] text-white p-2 rounded-full cursor-pointer flex items-center justify-center"
          onClick={scrollToTop}
          style={{ width: '50px', height: '50px' }} // This makes the button round
        >
          <Icon icon="formkit:arrowup" height={25} />
        </button>
      )}
    </div>
  );
}
