import React, { useEffect, useState, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Button } from '../ui/Buttons';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { Squash as Hamburger } from 'hamburger-react';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setIsOpen(false));

  useEffect(() => {
    AOS.init({
      duration: 1000
    });
  }, []);

  return (
    <React.Fragment>
      <section className="top-0 left-0 right-0 bg-transparent bs-section-dragged">
        <nav className="bg-black">
          <div className="container mx-auto px-4 py-1 lg:py-5">
            <div className="flex items-center justify-between">
              <a className="flex items-center gap-2" href="/">
                <img
                  className="h-12 sm:h-16"
                  src="https://static.shuffle.dev/uploads/files/2f/2f3a5efec3ce75cf36a8ad7d5268671cc2f842e4/3-1.png"
                  alt="Logo"
                />
                <span className="hidden text-white sm:block text-lg sm:text-xl">
                  Parentheses Talent
                </span>
              </a>
              <div ref={ref} className="lg:hidden bg-black text-white z-10">
                <Hamburger toggled={isOpen} toggle={setIsOpen} size={20} />
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="fixed z-[1000] left-0 shadow-4xl right-0 top-[3.5rem] p-5 pt-0 bg-black"
                      style={{ zIndex: 1000 }}
                    >
                      <ul className="grid p-5 z-50 bg-black  gap-2 text-white">
                        {['/', '/about', '/services', '/blogs', '/contact'].map((path, idx) => (
                          <motion.li
                            key={path}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: idx * 0.1 }}
                            className="w-full border border-[#3a3a3a] border-x-0 border-t-0 rounded-xl "
                          >
                            <Link
                              onClick={() => setIsOpen(false)}
                              className="flex items-center justify-between w-full p-4 rounded-xl bg-neutral-950"
                              to={path}
                            >
                              <span className="flex gap-1 text-lg">
                                {['Home', 'About', 'Services', 'Blogs', 'Contact Us'][idx]}
                              </span>
                            </Link>
                          </motion.li>
                        ))}
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <div className="hidden lg:flex items-center text-white">
                <Link to={'/'} className="inline-block hover:underline mr-10 font-medium">
                  Home
                </Link>
                <Link to={'/about'} className="inline-block hover:underline mr-10 font-medium">
                  About
                </Link>
                <Link to={'/services'} className="inline-block hover:underline mr-10 font-medium">
                  Services
                </Link>
                <Link to={'/blogs'} className="inline-block hover:underline mr-10 font-medium">
                  Blogs
                </Link>
                <Link to={'/contact'} className="inline-block hover:underline mr-10 font-medium">
                  Contact Us
                </Link>
              </div>
              <Button className="hidden lg:inline-flex">Book a call</Button>
            </div>
          </div>
        </nav>
      </section>
    </React.Fragment>
  );
}
