/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
export function Button({ size = 'small', children, type = 'link', ...props }) {
  const style =
    size === 'small'
      ? `${props.className} group inline-flex py-4 px-8 items-center justify-center leading-none font-medium text-white rounded-full bg-blue-500 border-2 border-blue-600 hover:text-black hover:bg-white transition duration-200`
      : `${props.className} group text-sm inline-flex px-4 sm:px-8 items-center justify-center leading-none font-medium text-white rounded-full transition duration-200 sm:text-2xl py-4 sm:py-5 bg-blue-500 border-2 border-blue-600 hover:text-black hover:bg-white`;

  return type === 'link' ? (
    <a className={style} href="#">
      <span className="mr-2">{children}</span>
      <span className="group-hover:rotate-45 transform transition duration-100">
        <svg
          width={10}
          height={10}
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 1L1 9"
            stroke="currentColor"
            strokeWidth="1.3"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 8.33571V1H1.66429"
            stroke="currentColor"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </a>
  ) : (
    <button {...props} className={style}>
      <span className="mr-2">{children}</span>
      <span className="group-hover:rotate-45 transform transition duration-100">
        <svg
          width={10}
          height={10}
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 1L1 9"
            stroke="currentColor"
            strokeWidth="1.3"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 8.33571V1H1.66429"
            stroke="currentColor"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </button>
  );
}
