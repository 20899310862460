import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import bg from '../../assets/bg.webp';
import { Outlet } from 'react-router-dom';
import Navbar from '../headers/Navbar';
import IndexSectionFooters10 from '../footers/IndexSectionFooters10';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: []
};

export default function Layout() {
  useEffect(() => {
    // Custom CSS classes for elements from the index.html
    let classes = document.body.classList;
    document.body.classList.remove(...classes);
    document.body.classList.add(
      ...'antialiased font-body bg-body text-body text-center overflow-x-hidden'.split(' ')
    );
  });

  return (
    <React.Fragment>
      <div
        style={{
          overflowX: 'hidden',
          position: 'relative'
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${bg})`,
            opacity: 0.3,
            zIndex: -1
          }}
        ></div>
        <HelmetProvider>
          <Helmet {...meta}></Helmet>
        </HelmetProvider>
        <Navbar style={{ zIndex: 1000 }} />
        <Outlet />
        <IndexSectionFooters10 />
      </div>
    </React.Fragment>
  );
}
