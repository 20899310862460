import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import BackToTop from './components/backtotop/backtotop.js';
import Layout from './components/ui/layout.js';
import Loader from './components/ui/Loader.js';

const IndexPage = lazy(() => import('./pages/Index.js'));
const ContactPage = lazy(() => import('./pages/Contact.js'));
const AboutUs = lazy(() => import('./pages/AboutUs.js'));
const Services = lazy(() => import('./pages/Services.js'));
const Blogs = lazy(() => import('./pages/Blogs.js'));
const BlogPage = lazy(() => import('./pages/BlogPage.js'));

function App() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<IndexPage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:blogId" element={<BlogPage />} />
          </Route>
        </Routes>
        <BackToTop />
      </Suspense>
    </>
  );
}
export default App;
