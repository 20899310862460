/* eslint-disable react/react-in-jsx-scope */

import LoaderImg from '../../assets/asitrastudio-assets/loader.svg';

export default function Loader() {
  return (
    <div className="absolute bg-black flex justify-center items-center inset-0">
      <img src={LoaderImg} alt="loader" className="w-20 " />
    </div>
  );
}
